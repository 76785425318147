import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Search" component="search" variation="search--extra-large" hasLightVersion hasReactVersion hasVueVersion="search--default" hasAngularVersion="?path=/story/search--basic" codepen="dLMpqv" mdxType="ComponentCode" />
    <ComponentCode name="Small search" component="search" variation="search--small" hasLightVersion hasReactVersion hasVueVersion="search--default&knob-small=true" hasAngularVersion="?path=/story/search--basic&knob-size=sm&knob-theme=dark&knob-disabled=&knob-placeholder=Search" codepen="dLMpQv" mdxType="ComponentCode" />
    <ComponentDocs component="search" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      